.sectionMain {
  padding: 0 0 60px;

  @media (min-width: 1080px) {
    padding: 30px 0 110px;
  }
}

.sectionMainDark {
  background: var(--bg);
  padding: 40px 0;
  overflow: hidden;

  @media (min-width: 1080px) {
    padding: 90px 0;
  }
}

.sectionMain:not([class*=dark]) + .sectionMain:not([class*=dark]) {
  padding-top: 0
}

.sectionMainContainer {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: 1550px) {
    max-width: 1300px;
  }
}

.sectionMainHeader {
  text-align: center;
  margin-bottom: 34px;

  @media (min-width: 1080px) {
    margin-bottom: 60px;
    text-align: left;
    margin-left: 200px;
  }
}

.sectionMainHeadline {
  margin: 0;
  font-weight: 700;
  font-size: 34px;
  line-height: 1.2;
  color: var(--text-base);
  text-transform: capitalize;

  @media (min-width: 1080px) {
    font-size: 70px;
    line-height: 1.4;
  }
}

.sectionMainHeadline br {
  display: initial;

  @media (min-width: 1080px) {
    display: none;
  }
}

.sectionMainHeadline b,
.sectionMainHeadline strong {
  font-weight: 700;
}

.sectionMainText {
  display: flex;
  align-items: center;
  color: var(--blue-grey-color-200);
  font-size: 14px;
  justify-content: center;
  line-height: 1.4;
  font-weight: 400;
  margin-bottom: 12px;
  gap: 12px;

  @media (min-width: 1080px) {
    font-size: 24px;
    justify-content: left;
  }
}

.sectionMainFlag {
  width: 35px;
  height: 26px;
}

.sectionMainFlag img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}

.sectionMainFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  @media (min-width: 1080px) {
    margin-top: 80px;
  }
}

.sectionMainMore {
  outline: 0;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  border: 0;
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  padding: 16px 0;
  background-color: #4faf4d;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  letter-spacing: .02em;
  text-transform: uppercase;
  text-align: center;
  border-radius: 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (min-width: 1080px) {
    padding: 22px 58px;
    font-size: 20px;
    line-height: 26px;
  }
}

.sectionMainMore:hover {
  background-color: #53cc51;
}
.sectionMainMore:active {
  background-color: #47a245;
}

.videoInner {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%
}
.videoFrame {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%
}
.videoFrame img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.videoDescription {
  margin-top: 30px;
  display: block;

  @media (min-width: 1080px) {
    margin-top: 50px;
    display: flex;
  }
}

.wrapperButton {
  text-align: center;
  margin-top: 50px;

  @media (min-width: 1080px) {
    text-align: left;
  }
}

.button {
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 17px 70px 16px 48px;
  background: url("/images/aboutUs/arrow22.svg") 91% 47% no-repeat, #4FAF4D;
  border-radius: 100px;


  @media (min-width: 1080px) {
    font-size: 20px;
    line-height: 130%;
    padding: 22px 83px 22px 58px;
  }
}

.videoText > :first-child {
  margin-top: 0
}

.videoText > :last-child {
  margin-bottom: 0
}

.videoText b,
.videoText strong {
  font-weight: 700;
  color: var(--vitaliv)
}

.videoText {
  font-size: 20px;
  line-height: 1.5;
  flex: 1;
  font-weight: 400;
  color: var(--text-mid);
  letter-spacing: .02em;

  @media (min-width: 1080px) {
    font-size: 24px;
    line-height: 1.6;
  }
}

.videoText p {
  margin: 12px 0;
  line-height: inherit;
  font-size: inherit;
  font-weight: 600;
  max-width: 620px;

  @media (min-width: 1080px) {
    margin: 40px 0;
  }
}

.videoSide {
  margin-left: 0;
  margin-top: 24px;
  flex: initial;

  @media (min-width: 1080px) {
    margin-left: 60px;
    flex: 0 1 633px;
    margin-top: 0;
  }
}

.videoList {
  padding: 30px 40px;
  background: #f3f8f8;
  border-radius: 30px;

  @media (min-width: 1080px) {
    padding: 40px 70px;
  }
}

.videoItem {
  display: block;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  color: var(--text-mid);

  @media (min-width: 1080px) {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 1.7;
  }
}

.videoItem:not(:last-child) {
  margin-bottom: 10px;

  @media (min-width: 1080px) {
    margin-bottom: 14px;
  }
}

.videoNumber {
  margin-right: 0;
  font-size: 60px;
  color: #f3f8f8;
  text-shadow: 2px 0 0 var(--vitaliv),-2px 0 0 var(--vitaliv),0 2px 0 var(--vitaliv),0 -2px 0 var(--vitaliv),1px 1px var(--vitaliv),-1px -1px 0 var(--vitaliv),1px -1px 0 var(--vitaliv),-1px 1px 0 var(--vitaliv);
  line-height: 1.4;
  font-weight: 700;
  white-space: nowrap;
  flex-shrink: 0;

  @media (min-width: 1080px) {
    margin-right: 40px;
    font-size: 100px;
  }
}

.videoText p span {
  position: relative;
  z-index: 1;
}

.videoText p span:before {
  content: ' ';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 11px;
  background: #2ACEEE;
  z-index: -1;
}